import { EAdminUserRole } from '../repositories/types';

export type AdminUserValidationError = {
  message: string;
};

export type AdminUserListRequestVariables = {
  adminUsersInput: {
    pagination: {
      skip: number;
      take: number;
    };
  };
};

export type AdminUserListResponse = {
  totalCount: number;
  adminUsers: AdminUserItemResponse[];
};

export type AdminUserItemResponse = {
  id: string;
  email: string;
  team: string;
  name: string;
  roles: EAdminUserRole[];
  permissions: AdminPermissionInput[];
};

export type UpdateAdminUserInput = {
  team: string;
  name: string;
  permissions: AdminPermissionInput[];
};

export type AdminPermissionInput = {
  object: EAuthObject;
  permission: EAuthPermission;
};

export enum EAuthObject {
  ADMIN_USERS = 'ADMIN_USERS',
  KEMI_USERS = 'KEMI_USERS',
  ORDERS = 'ORDERS',
  PRODUCTS = 'PRODUCTS',
  HISTORIES = 'HISTORIES',
}

export enum EAuthPermission {
  EDIT = 'EDIT',
  READ = 'READ',
}

export enum EAuthPermissionAndObject {
  EDIT_ADMIN_USERS = 'EDIT_ADMIN_USERS',
  READ_ADMIN_USERS = 'READ_ADMIN_USERS',
  EDIT_KEMI_USERS = 'EDIT_KEMI_USERS',
  READ_KEMI_USERS = 'READ_KEMI_USERS',
  EDIT_ORDERS = 'EDIT_ORDERS',
  READ_ORDERS = 'READ_ORDERS',
  EDIT_PRODUCTS = 'EDIT_PRODUCTS',
  READ_PRODUCTS = 'READ_PRODUCTS',
  READ_HISTORIES = 'READ_HISTORIES',
  READ_KEMI_USER_ACCESS_TOKEN = 'READ_KEMI_USER_ACCESS_TOKEN',
}
