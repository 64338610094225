import { InvalidParameterError } from '../models/error';

class EnvironmentService {
  private DEVEL = 'devel';
  private STAGE = 'stage';

  appEnvs = {
    [this.DEVEL]: '개발환경',
    [this.STAGE]: '베타테스트',
  };

  get(env?: string): string {
    if (env) {
      return env as string;
    }

    throw new InvalidParameterError(
      'INVALID_ENV_PARAMETER',
      `env ${env} is invalid format`
    );
  }

  isDevel(): boolean {
    const appEnv = this.get(process.env.NEXT_PUBLIC_APP_ENV);
    return appEnv === this.DEVEL;
  }

  getAppEnv(): string {
    const appEnv = this.appEnvs[process.env.NEXT_PUBLIC_APP_ENV || ''];

    return appEnv || '';
  }
}

export default new EnvironmentService();
