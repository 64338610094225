import { CheckboxCheckedOptionType } from '../components/Fields/CheckboxField';
import {
  AdminPermissionInput,
  EAuthPermissionAndObject,
} from '../models/admin-user';
import {
  AdminPermission,
  AdminUsersSearchInput,
  EAdminUserRole,
  EAuthObject,
  EAuthPermission,
} from '../repositories/types';

export const getAdminUserSearchVariables = ({
  name,
}: {
  name?: string;
}): {
  search?: AdminUsersSearchInput;
} => {
  const searchVariables: {
    search?: AdminUsersSearchInput;
  } = {};

  if (name) {
    searchVariables.search = {
      field: 'name',
      word: name,
    };
  }

  return searchVariables;
};

export const mapUserPermissionToUIForm = (
  permission: AdminPermission
): CheckboxCheckedOptionType | null => {
  if (!permission.permission || !permission.object) return null;

  return { value: toPermissionValue(permission.permission, permission.object) };
};

export const mapUserPermissionsToUIForm = (
  permissions: AdminPermission[]
): CheckboxCheckedOptionType[] => {
  return permissions
    .map((permission) => mapUserPermissionToUIForm(permission))
    .filter(
      (permission): permission is CheckboxCheckedOptionType => !!permission
    );
};

export const mapUserPermissionToApiForm = (
  checkedOption: CheckboxCheckedOptionType
): AdminPermissionInput => {
  return splitPermissionAndObject(checkedOption.value) as AdminPermissionInput;
};

const splitPermissionAndObject = (value: string) => {
  // 타입이 리터럴이라 이렇게 정의할 수 밖에 없음. 서버 스키마가 바뀌면 변경 요망.
  const permissionTypes: EAuthPermission[] = ['READ', 'EDIT'];
  let permission = '';
  let object = '';

  permissionTypes.forEach((permissionType) => {
    if (value.includes(permissionType)) {
      permission = permissionType;
      object = value.replace(permission, '').slice(1);
    }
  });

  return { permission, object };
};

export const toPermissionValue = (
  permission: EAuthPermission,
  object: EAuthObject
) => `${permission}_${object}`;

export const hasPermission = (
  roles: (EAdminUserRole | null)[],
  permissions: (AdminPermission | null)[],
  role: EAuthPermissionAndObject
): boolean => {
  const isSuperAdmin = roles?.[0] === 'SUPER_ADMIN';

  if (isSuperAdmin) {
    return true;
  }

  return permissions?.some((permission) => {
    if (permission?.permission && permission?.object) {
      return (
        toPermissionValue(permission.permission, permission.object) === role
      );
    }

    return false;
  });
};
