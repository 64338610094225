import * as Sentry from '@sentry/nextjs';
import { Typography } from 'antd';
import type { NextPage } from 'next';
import Image from 'next/image';

import AppLayout from '@components/layouts/AppLayout';
import { useCachedAdminMeQuery } from '@repositories/adminUser/adminUserHooks';

const Home: NextPage = () => {
  const { data } = useCachedAdminMeQuery();

  if (data) {
    Sentry.setUser({
      userId: data?.adminMe?.id,
      email: data?.adminMe?.email,
      username: data?.adminMe?.name,
    });
  }

  return (
    <AppLayout>
      <Typography.Paragraph>{'너와 나의 케미스트'}</Typography.Paragraph>
      <Typography.Paragraph>
        {'좌측 메뉴 클릭이 안된다면 팀장에게 권한을 요청해주세요'}
      </Typography.Paragraph>
      <div>
        <Image src={'/main.png'} width={800} height={500} />
      </div>
    </AppLayout>
  );
};

export default Home;
