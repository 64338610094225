import * as Sentry from '@sentry/nextjs';
import {
  Button,
  Col,
  Divider,
  Layout,
  Row,
  Space,
  Switch,
  Typography,
} from 'antd';
import Image from 'next/image';
import Link from 'next/link';
import { useRouter } from 'next/router';
import { useQueryClient } from 'react-query';

import { ROUTE } from '../../router';
import { removeAuthToken } from '../../services/AuthService';
import EnvironmentService from '../../services/EnvironmentService';

import styles from './Header.module.css';

import useFormatPhone from 'src/hooks/usePhoneNumber';

const { Text } = Typography;

type HeaderPropsTypes = {
  adminEmail: string;
};

const Header = ({ adminEmail }: HeaderPropsTypes) => {
  const router = useRouter();
  const queryClient = useQueryClient();
  const { enableFormatPhone, setEnableFormatPhone } = useFormatPhone();

  const appEnv = EnvironmentService.getAppEnv();

  const handleLogoutButtonClick = () => {
    removeAuthToken();
    Sentry.setUser(null);
    queryClient.clear();
    router.push(ROUTE.login);
  };

  return (
    <Layout.Header style={{ backgroundColor: '#ffffff' }}>
      <Row justify={'space-between'}>
        <Col>
          <Link href={'/'}>
            <a>
              <Space size={'small'}>
                <div className={styles['image-wrapper']}>
                  <Image
                    alt={'kemi'}
                    src={'/kemi.svg'}
                    width={20}
                    height={20}
                  />
                  <Image
                    alt={'kemi-partners'}
                    src={'/kemi-partners-cloud.svg'}
                    width={20}
                    height={20}
                  />
                </div>
                <Text strong>{`케미스트${appEnv ? ` - ${appEnv}` : ''}`}</Text>
              </Space>
            </a>
          </Link>
        </Col>
        <Col>
          <div
            style={{
              display: 'flex',
              justifyContent: 'end',
              alignItems: 'center',
            }}
          >
            <Switch
              checkedChildren={'전화번호형식(010-1111-1111)'}
              unCheckedChildren={'전화번호형식(01011111111)'}
              checked={enableFormatPhone}
              onChange={setEnableFormatPhone}
            />
            <Divider type={'vertical'} />
            <div className={styles['image-wrapper']}>
              <Image
                alt={'kemi-profile'}
                src={'/kemi-profile.svg'}
                width={20}
                height={20}
              />
            </div>
            <Space>
              <Text>{adminEmail}</Text>
              <Button type={'link'} onClick={handleLogoutButtonClick}>
                {'로그아웃'}
              </Button>
            </Space>
          </div>
        </Col>
      </Row>
    </Layout.Header>
  );
};

export default Header;
