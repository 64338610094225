import * as Types from '../types';

import {
  useMutation,
  UseMutationOptions,
  useQuery,
  UseQueryOptions,
  useInfiniteQuery,
  UseInfiniteQueryOptions,
  QueryFunctionContext,
} from 'react-query';
import { fetcher } from '@repositories/fetcher/graphql-request';
export type RegisterAdminUserMutationVariables = Types.Exact<{
  registerAdminUserInput: Types.RegisterAdminUserInput;
}>;

export type RegisterAdminUserMutationResponse = {
  registerAdminUser?: { id: string } | null;
};

export type AdminMeQueryVariables = Types.Exact<{ [key: string]: never }>;

export type AdminMeQueryResponse = {
  adminMe?: {
    id: string;
    email: string;
    team: string;
    roles?: Array<Types.EAdminUserRole | null> | null;
    name: string;
    permissions?: Array<{
      object?: Types.EAuthObject | null;
      permission?: Types.EAuthPermission | null;
    } | null> | null;
  } | null;
};

export type AdminLoginMutationVariables = Types.Exact<{
  adminLoginInput: Types.AdminLoginInput;
}>;

export type AdminLoginMutationResponse = {
  adminLogin: { accessToken: string };
};

export const RegisterAdminUserDocument = `
    mutation RegisterAdminUser($registerAdminUserInput: RegisterAdminUserInput!) {
  registerAdminUser(registerAdminUserInput: $registerAdminUserInput) {
    id
  }
}
    `;
export const useRegisterAdminUserMutation = <
  TError = unknown,
  TContext = unknown
>(
  options?: UseMutationOptions<
    RegisterAdminUserMutationResponse,
    TError,
    RegisterAdminUserMutationVariables,
    TContext
  >
) =>
  useMutation<
    RegisterAdminUserMutationResponse,
    TError,
    RegisterAdminUserMutationVariables,
    TContext
  >(
    ['RegisterAdminUser'],
    (variables?: RegisterAdminUserMutationVariables) =>
      fetcher<
        RegisterAdminUserMutationResponse,
        RegisterAdminUserMutationVariables
      >(RegisterAdminUserDocument, variables)(),
    options
  );
useRegisterAdminUserMutation.getKey = () => ['RegisterAdminUser'];

useRegisterAdminUserMutation.fetcher = (
  variables: RegisterAdminUserMutationVariables,
  options?: RequestInit['headers']
) =>
  fetcher<
    RegisterAdminUserMutationResponse,
    RegisterAdminUserMutationVariables
  >(RegisterAdminUserDocument, variables, options);
export const AdminMeDocument = `
    query AdminMe {
  adminMe {
    id
    email
    team
    roles
    name
    permissions {
      object
      permission
    }
  }
}
    `;
export const useAdminMeQuery = <TData = AdminMeQueryResponse, TError = unknown>(
  variables?: AdminMeQueryVariables,
  options?: UseQueryOptions<AdminMeQueryResponse, TError, TData>
) =>
  useQuery<AdminMeQueryResponse, TError, TData>(
    variables === undefined ? ['AdminMe'] : ['AdminMe', variables],
    fetcher<AdminMeQueryResponse, AdminMeQueryVariables>(
      AdminMeDocument,
      variables
    ),
    options
  );

useAdminMeQuery.getKey = (variables?: AdminMeQueryVariables) =>
  variables === undefined ? ['AdminMe'] : ['AdminMe', variables];
export const useInfiniteAdminMeQuery = <
  TData = AdminMeQueryResponse,
  TError = unknown
>(
  variables?: AdminMeQueryVariables,
  options?: UseInfiniteQueryOptions<AdminMeQueryResponse, TError, TData>
) => {
  return useInfiniteQuery<AdminMeQueryResponse, TError, TData>(
    variables === undefined
      ? ['AdminMe.infinite']
      : ['AdminMe.infinite', variables],
    (metaData) =>
      fetcher<AdminMeQueryResponse, AdminMeQueryVariables>(AdminMeDocument, {
        ...variables,
        ...(metaData.pageParam ?? {}),
      })(),
    options
  );
};

useInfiniteAdminMeQuery.getKey = (variables?: AdminMeQueryVariables) =>
  variables === undefined
    ? ['AdminMe.infinite']
    : ['AdminMe.infinite', variables];
useAdminMeQuery.fetcher = (
  variables?: AdminMeQueryVariables,
  options?: RequestInit['headers']
) =>
  fetcher<AdminMeQueryResponse, AdminMeQueryVariables>(
    AdminMeDocument,
    variables,
    options
  );
export const AdminLoginDocument = `
    mutation AdminLogin($adminLoginInput: AdminLoginInput!) {
  adminLogin(adminLoginInput: $adminLoginInput) {
    accessToken
  }
}
    `;
export const useAdminLoginMutation = <TError = unknown, TContext = unknown>(
  options?: UseMutationOptions<
    AdminLoginMutationResponse,
    TError,
    AdminLoginMutationVariables,
    TContext
  >
) =>
  useMutation<
    AdminLoginMutationResponse,
    TError,
    AdminLoginMutationVariables,
    TContext
  >(
    ['AdminLogin'],
    (variables?: AdminLoginMutationVariables) =>
      fetcher<AdminLoginMutationResponse, AdminLoginMutationVariables>(
        AdminLoginDocument,
        variables
      )(),
    options
  );
useAdminLoginMutation.getKey = () => ['AdminLogin'];

useAdminLoginMutation.fetcher = (
  variables: AdminLoginMutationVariables,
  options?: RequestInit['headers']
) =>
  fetcher<AdminLoginMutationResponse, AdminLoginMutationVariables>(
    AdminLoginDocument,
    variables,
    options
  );
