import Image from 'next/image';

import kemiFullpageLoader from '../../public/kemi-fullpage-loader.gif';

const PageLoader = () => {
  return (
    <div
      style={{
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        width: '100%',
        height: '100%',
        background: '#ffffffd1',
        position: 'fixed',
        top: 0,
        left: 0,
        zIndex: 1,
      }}
    >
      <Image src={kemiFullpageLoader} alt={'loader'} width={176} height={32} />
    </div>
  );
};

export default PageLoader;
