import { GraphQLClient } from 'graphql-request';
import { RequestInit } from 'graphql-request/dist/types.dom';

import { getAuthToken } from '../../services/AuthService';

export const client = new GraphQLClient(
  process.env.NEXT_PUBLIC_GRAPHQL_API_URL as string
);

export function fetcher<TData, TVariables>(
  query: string,
  variables?: TVariables,
  headers?: RequestInit['headers']
) {
  const auth = getAuthToken();
  const Authorization = auth?.token ? `Bearer ${auth.token}` : '';

  client.setHeaders({
    Authorization,
  });
  return async (): Promise<TData> =>
    client.request<TData, TVariables>(query, variables, headers);
}
