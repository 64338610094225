import { UseQueryOptions } from 'react-query';

import {
  AdminMeQueryResponse,
  AdminMeQueryVariables,
  useAdminMeQuery,
} from './adminUser.generated';

export const useCachedAdminMeQuery = <
  TData = AdminMeQueryResponse,
  TError = unknown
>(
  variables?: AdminMeQueryVariables,
  options?: UseQueryOptions<AdminMeQueryResponse, TError, TData>
) => {
  return useAdminMeQuery(variables, {
    staleTime: Infinity, // 로그인 후에 모든 admin query 요청에 대해 cache 값을 반환한다.
    ...options,
  });
};
