import { AdminPermissionInput } from './../models/admin-user';

export const FULL_PERMISSIONS_UI_FORM = [
  {
    value: 'READ_PRODUCTS',
    label: '상품관리 읽기',
  },
  {
    value: 'EDIT_PRODUCTS',
    label: '상품관리 쓰기',
  },
  {
    value: 'READ_ORDERS',
    label: '주문관리 읽기',
  },
  {
    value: 'EDIT_ORDERS',
    label: '주문관리 쓰기',
  },
  {
    value: 'READ_KEMI_USERS',
    label: '케미 회원관리 읽기',
  },
  {
    value: 'EDIT_KEMI_USERS',
    label: '케미 회원관리 쓰기',
  },
  {
    value: 'READ_ADMIN_USERS',
    label: '어드민 회원관리 읽기',
  },
  {
    value: 'EDIT_ADMIN_USERS',
    label: '어드민 회원관리 쓰기',
  },
  {
    value: 'READ_HISTORIES',
    label: '시스템 히스토리 읽기',
  },
  {
    value: 'READ_KEMI_USER_ACCESS_TOKEN',
    label: '케미 회원 엑세스 토큰',
  },
];

export const FULL_PERMISSIONS_API_FORM = [
  { object: 'PRODUCTS', permission: 'EDIT' },
  { object: 'PRODUCTS', permission: 'READ' },
  { object: 'ORDERS', permission: 'EDIT' },
  { object: 'ORDERS', permission: 'READ' },
  { object: 'KEMI_USERS', permission: 'EDIT' },
  { object: 'KEMI_USERS', permission: 'READ' },
  { object: 'ADMIN_USERS', permission: 'EDIT' },
  { object: 'ADMIN_USERS', permission: 'READ' },
  { object: 'HISTORIES', permission: 'READ' },
  { object: 'KEMI_USER_ACCESS_TOKEN', permission: 'READ' },
] as AdminPermissionInput[];

export const FULL_PERMISSIONS_ONLY_VALUE = FULL_PERMISSIONS_UI_FORM.map(
  (PERMISSION) => PERMISSION.value
);
