export const ROUTE = {
  home: '/',
  join: '/join',
  login: '/login',
  _401: '/401',
  _404: '/404',
  info: '/info',
  requestPermission: '/request-permission',
  products: {
    list: '/products/list',
    new: '/products/new',
    detail: '/products/[productId]',
    cards: '/products/card-list',
  },
  business: {
    list: '/suggest-list',
    detail: '/suggest-list/[suggestId]',
  },
  orders: {
    list: '/orders',
    detail: '/orders/[orderId]',
  },
  orderLineItems: {
    list: '/order-line-items',
    detail: 'order-line-items/[orderLineItems]',
  },
  kemiUsers: {
    list: '/kemi-user/list',
    detail: '/kemi-user/[kemiUserId]',
  },
  adminUsers: {
    list: '/admin-user/list',
    detail: '/admin-user/[adminUserId]',
  },
  system: {
    history: {
      list: '/system/history/list',
    },
  },
};
