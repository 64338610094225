// This file configures the initialization of Sentry on the browser.
// The config you add here will be used whenever a page is visited.
// https://docs.sentry.io/platforms/javascript/guides/nextjs/

import * as Sentry from '@sentry/nextjs';

const SENTRY_USE = process.env.NEXT_PUBLIC_SENTRY_USE === 'true';
const SENTRY_ENVIRONMENT = process.env.NEXT_PUBLIC_APP_ENV;
const SENTRY_DSN = process.env.NEXT_PUBLIC_SENTRY_DSN;

Sentry.init({
  environment: SENTRY_ENVIRONMENT,
  dsn: SENTRY_USE ? SENTRY_DSN : false,
  // Adjust this value in production, or use tracesSampler for greater control
  tracesSampleRate: 0.01,
  // ...
  // Note: if you want to override the automatic release value, do not set a
  // `release` value here - use the environment variable `SENTRY_RELEASE`, so
  // that it will also get attached to your source maps
});

/**
 * 이미지, css, js 로드 시 404에러를 감지하여 센트리에 발송한다.
 */
document.body.addEventListener(
  'error',
  (event) => {
    if (!event.target) {
      return;
    }

    if (event.target.tagName === 'IMG') {
      Sentry.captureMessage(
        `Failed to load image: ${event.target.src}`,
        'warning'
      );
    } else if (event.target.tagName === 'LINK') {
      Sentry.captureMessage(
        `Failed to load css: ${event.target.href}`,
        'warning'
      );
    } else if (event.target.tagName === 'SCRIPT') {
      Sentry.captureMessage(
        `Failed to load script: ${event.target.src}`,
        'warning'
      );
    }
  },
  /**
   * 일반적인 런타임 에러와 다르게 리소스 로드 실패 이벤트는 버블링이 되지 않는다.
   * 그래서 캡쳐링 이벤트를 핸들링해야한다.
   */
  true
);
