import { useCallback } from 'react';
import { useLocalStorage } from 'usehooks-ts';

import { formatPhone as formatPhoneOrigin } from '@utils/string-utils';

const useFormatPhone = () => {
  const [enableFormatPhone, setEnableFormatPhone] = useLocalStorage(
    'enableFormatPhone',
    false
  );

  const formatPhone = useCallback(
    (phone: string) => {
      if (enableFormatPhone) {
        return formatPhoneOrigin(phone, false);
      }

      return phone;
    },
    [enableFormatPhone, formatPhoneOrigin]
  );

  return {
    enableFormatPhone,
    setEnableFormatPhone,
    formatPhone,
  };
};

export default useFormatPhone;
