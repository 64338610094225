export const isEmpty = (str?: string) => !str || str === '';

export const trimAllSpace = (str: string) => {
  return str.split(' ').join('');
};

export const onlyNumber = (str: string): string => {
  const regex = /[^0-9]/g;
  return str.replace(regex, '');
};

export const formatNumber = (
  value: string | number,
  format: '#,###' | '####'
) => {
  switch (format) {
    case '#,###':
      const regexp = /\B(?=(\d{3})+(?!\d))/g;
      return value.toString().replace(regexp, ',');
    case '####':
      return value.toString().split(',').join('');
  }
};

export const formatBizNumber = (num: string, masking: boolean) => {
  var formatNum = '';

  if (masking) {
    if (num.length === 10) {
      formatNum = num.replace(/(\d{3})(\d{2})(\d{5})/, '$1-$2-*****');
    } else if (num.length === 5) {
      formatNum = num.replace(/(\d{3})(\d{2})/, '$1-$2');
    } else {
      formatNum = num.replace(
        /(\d{3})(\d{2})(\d{1,5})/,
        `$1-$2-${Array(num.length - 5 + 1).join('*')}`
      );
    }
  } else {
    if (num.length === 10) {
      formatNum = num.replace(/(\d{3})(\d{2})(\d{5})/, '$1-$2-$3');
    } else if (num.length === 5) {
      formatNum = num.replace(/(\d{3})(\d{2})/, '$1-$2');
    } else {
      formatNum = num.replace(/(\d{3})(\d{2})(\d{1,5})/, '$1-$2-$3');
    }
  }

  return formatNum;
};

/** kemi-playground 내 string.ts 유틸 모듈 사용 */
export const formatPhone = (phone: string, masking: boolean) => {
  let formatNum;

  if (phone.length == 11) {
    if (masking) {
      formatNum = phone.replace(/(\d{3})(\d{4})(\d{4})/, '$1-****-$3');
    } else {
      formatNum = phone.replace(/(\d{3})(\d{4})(\d{4})/, '$1-$2-$3');
    }
  } else if (phone.length == 8) {
    formatNum = phone.replace(/(\d{4})(\d{4})/, '$1-$2');
  } else {
    if (phone.indexOf('02') == 0) {
      if (masking) {
        formatNum = phone.replace(/(\d{2})(\d{4})(\d{4})/, '$1-****-$3');
      } else {
        formatNum = phone.replace(/(\d{2})(\d{4})(\d{4})/, '$1-$2-$3');
      }
    } else {
      if (masking) {
        formatNum = phone.replace(/(\d{3})(\d{3})(\d{4})/, '$1-***-$3');
      } else {
        formatNum = phone.replace(/(\d{3})(\d{3})(\d{4})/, '$1-$2-$3');
      }
    }
  }

  return formatNum;
};
