export const getItem = <T>(key: string): T | null => {
  try {
    const item = localStorage.getItem(key);

    return JSON.parse(item || '') || null;
  } catch (error) {
    return null;
  }
};

export const setItem = <T>(key: string, value: T) => {
  const item = JSON.stringify(value);

  localStorage.setItem(key, item);
};

export const removeItem = (key: string) => {
  localStorage.removeItem(key);
};
