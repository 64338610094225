import { ConfigProvider, message } from 'antd';
import React, { useEffect } from 'react';

import theme from '../theme';

export const initializeUI = () => {
  typeof window !== 'undefined' &&
    ConfigProvider.config({
      theme: {
        primaryColor: theme.color.primary,
      },
    });

  if (typeof document === 'undefined') {
    React.useLayoutEffect = useEffect;
  }

  message.config({
    top: 200,
  });
};
