import 'antd/dist/antd.variable.min.css';
import '../styles/globals.css';
import '../styles/antd.override.css';
import '../styles/common.css';

import { ErrorBoundary } from '@sentry/nextjs';
import { ConfigProvider } from 'antd';
import locale from 'antd/lib/locale/ko_KR';
import type { AppProps } from 'next/app';
import { useState } from 'react';
import { Hydrate, QueryClient, QueryClientProvider } from 'react-query';

import AuthRouter from '../components/AuthRouter';
import PageLoader from '../components/PageLoader';
import { usePageLoading } from '../hooks/usePageLoading';
import { initializeUI } from '../services/UIService';

import Custom500 from './500';

initializeUI();

function MyApp({
  Component,
  pageProps,
}: AppProps<{ dehydratedState: string }>) {
  const [queryClient] = useState(() => new QueryClient());
  const { isRouting } = usePageLoading();

  return (
    <ConfigProvider locale={locale}>
      <QueryClientProvider client={queryClient}>
        <Hydrate state={pageProps.dehydratedState}>
          <ErrorBoundary fallback={<Custom500 />}>
            <AuthRouter>
              <div
                style={{
                  minWidth: 1400,
                }}
              >
                <Component {...pageProps} />
                {isRouting && <PageLoader />}
              </div>
            </AuthRouter>
          </ErrorBoundary>
        </Hydrate>
      </QueryClientProvider>
    </ConfigProvider>
  );
}

export default MyApp;
