import { Breadcrumb, Layout } from 'antd';
import { PropsWithChildren } from 'react';

import { useCachedAdminMeQuery } from '../../repositories/adminUser/adminUserHooks';

import styles from './AppLayout.module.css';
import Header from './Header';
import Nav from './Nav';

type AppLayoutProps = PropsWithChildren<{
  hierarchyMenuItems?: string[];
  customContentLayout?: boolean;
}>;

const AppLayout = ({
  hierarchyMenuItems = [],
  customContentLayout,
  children,
}: AppLayoutProps) => {
  const { data } = useCachedAdminMeQuery();

  return (
    <Layout className={styles['container']}>
      <Header adminEmail={data?.adminMe?.email || ''} />
      <Layout>
        <Nav
          adminPermissions={data?.adminMe?.permissions}
          adminRoles={data?.adminMe?.roles}
        />
        <Layout className={styles['content-layout']}>
          <Breadcrumb className={styles['hierarchy-menu']}>
            {hierarchyMenuItems.map((menuItem) => (
              <Breadcrumb.Item key={menuItem}>{menuItem}</Breadcrumb.Item>
            ))}
          </Breadcrumb>
          {customContentLayout && <Layout.Content>{children}</Layout.Content>}
          {!customContentLayout && (
            <Layout.Content className={styles['content']}>
              {children}
            </Layout.Content>
          )}
        </Layout>
      </Layout>
    </Layout>
  );
};

export default AppLayout;
