import dayjs from 'dayjs';

import * as LocalStorageService from './LocalStorageService';

type accessTokenType = {
  token: string;
  maxAge: string;
};

const ACCESS_TOKEN_KEY = 'accessToken';
const ACCESS_TOKEN_MAX_AGE = 7 * 24 * 60 * 60 * 1000; //1week

export const getAuthToken = (): accessTokenType | null => {
  return LocalStorageService.getItem<accessTokenType>(ACCESS_TOKEN_KEY);
};

export const setAuthToken = (token: string) => {
  const maxAge = dayjs().add(ACCESS_TOKEN_MAX_AGE).toISOString();
  const item = { token, maxAge };

  LocalStorageService.setItem<accessTokenType>(ACCESS_TOKEN_KEY, item);
};

export const removeAuthToken = () => {
  LocalStorageService.removeItem(ACCESS_TOKEN_KEY);
};

export const isExpiredAuthToken = (): boolean => {
  const token = getAuthToken();

  if (!token) return true;

  return dayjs().isAfter(dayjs(token.maxAge));
};
