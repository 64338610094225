import { NextPage } from 'next';
import { useRouter } from 'next/router';
import { PropsWithChildren, useEffect, useState } from 'react';

import { ROUTE } from '../router';
import { isExpiredAuthToken } from '../services/AuthService';

import PageLoader from './PageLoader';

type AuthRouterProps = PropsWithChildren<{}>;

const whiteList = [ROUTE.join, ROUTE.login, ROUTE._401, ROUTE._404, ROUTE.info];

const AuthRouter: NextPage = ({ children }: AuthRouterProps) => {
  const router = useRouter();
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    if (whiteList.includes(router.pathname)) {
      setLoading(false);
      return;
    }

    if (isExpiredAuthToken()) {
      router.push(ROUTE.login);
      return;
    }

    setLoading(false);
  }, [router.pathname]);

  if (!loading) {
    // eslint-disable-next-line react/jsx-no-useless-fragment
    return <>{children}</>;
  }

  return <PageLoader />;
};

export default AuthRouter;
