import * as Sentry from '@sentry/nextjs';

import { Maybe } from './common';

import { EKemistErrorCode } from '@repositories/types';

export type GraphqlError = {
  extensions: {
    code: EKemistErrorCode;
    exception: {
      stacktrace: string[];
    };
  };
  locations: { line: number; column: number }[];
  message: string;
  path: string[];
}[];

export class KemistError extends Error {
  code: Maybe<string>;
  options: any;

  constructor(name: string, code: string, message: string, options?: any) {
    super(message);
    this.name = name;
    this.code = code;
    this.options = options;

    const extras = options;
    const tags = { code };

    extras && Sentry.setExtras(extras);
    tags && Sentry.setTags(tags);
    Sentry.captureException(this);
  }
}

export class KemistGwApiError extends KemistError {
  constructor(error: any, options?: any) {
    const errorInterface: GraphqlError = error.response.errors.map((e: any) => {
      return {
        message: e.message,
        path: e.path || [],
        extensions: {
          code: e.extensions.code,
          exception: e.extensions.exception,
        },
        locations: e.locations || [],
      };
    });

    super(
      'KemistGwApiError',
      errorInterface[0].extensions.code,
      errorInterface[0].message,
      options
    );
  }
}

export class ResourceNotFoundError extends KemistError {
  constructor(code: string, message: string) {
    super('ResourceNotFoundError', 'RESOURCE_NOT_FOUND_ERROR', message);
  }
}

export class InvalidParameterError extends KemistError {
  constructor(code: string, message: string) {
    super('InvalidParameterError', code || 'INVALID_PARAMETER_ERROR', message);
  }
}

export class InvalidValueError extends KemistError {
  constructor(code: string, message: string) {
    super('InvalidValueError', code || 'INVALID_VALUE_ERROR', message);
  }
}
